// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PhoneNumberFormRequestPhoneVerificationCodeMutationVariables =
  Types.Exact<{
    phoneNumber: Types.Scalars['String']['input'];
  }>;

export type PhoneNumberFormRequestPhoneVerificationCodeMutation = {
  __typename?: 'Mutation';
  requestPhoneVerificationCode: boolean;
};

export type PhoneNumberFormUpdateUserMutationVariables = Types.Exact<{
  smsOptedOutGroups?: Types.InputMaybe<
    Array<Types.SmsEnrollmentGroup> | Types.SmsEnrollmentGroup
  >;
}>;

export type PhoneNumberFormUpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    id: string;
    applicant?: {
      __typename?: 'Applicant';
      id: string;
      smsEnrollments: Array<{
        __typename?: 'SMSEnrollment';
        enrollmentGroup: Types.SmsEnrollmentGroup;
        enrolled: boolean;
      }>;
    } | null;
  };
};

export const PhoneNumberFormRequestPhoneVerificationCodeDocument = gql`
  mutation PhoneNumberForm_RequestPhoneVerificationCode($phoneNumber: String!) {
    requestPhoneVerificationCode(phoneNumber: $phoneNumber)
  }
`;
export type PhoneNumberFormRequestPhoneVerificationCodeMutationFn =
  Apollo.MutationFunction<
    PhoneNumberFormRequestPhoneVerificationCodeMutation,
    PhoneNumberFormRequestPhoneVerificationCodeMutationVariables
  >;

/**
 * __usePhoneNumberFormRequestPhoneVerificationCodeMutation__
 *
 * To run a mutation, you first call `usePhoneNumberFormRequestPhoneVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePhoneNumberFormRequestPhoneVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [phoneNumberFormRequestPhoneVerificationCodeMutation, { data, loading, error }] = usePhoneNumberFormRequestPhoneVerificationCodeMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function usePhoneNumberFormRequestPhoneVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PhoneNumberFormRequestPhoneVerificationCodeMutation,
    PhoneNumberFormRequestPhoneVerificationCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PhoneNumberFormRequestPhoneVerificationCodeMutation,
    PhoneNumberFormRequestPhoneVerificationCodeMutationVariables
  >(PhoneNumberFormRequestPhoneVerificationCodeDocument, options);
}
export type PhoneNumberFormRequestPhoneVerificationCodeMutationHookResult =
  ReturnType<typeof usePhoneNumberFormRequestPhoneVerificationCodeMutation>;
export type PhoneNumberFormRequestPhoneVerificationCodeMutationResult =
  Apollo.MutationResult<PhoneNumberFormRequestPhoneVerificationCodeMutation>;
export type PhoneNumberFormRequestPhoneVerificationCodeMutationOptions =
  Apollo.BaseMutationOptions<
    PhoneNumberFormRequestPhoneVerificationCodeMutation,
    PhoneNumberFormRequestPhoneVerificationCodeMutationVariables
  >;
export const PhoneNumberFormUpdateUserDocument = gql`
  mutation PhoneNumberForm_UpdateUser(
    $smsOptedOutGroups: [SMSEnrollmentGroup!]
  ) {
    updateUser(applicant: { smsOptedOutGroups: $smsOptedOutGroups }) {
      id
      applicant {
        id
        smsEnrollments {
          enrollmentGroup
          enrolled
        }
      }
    }
  }
`;
export type PhoneNumberFormUpdateUserMutationFn = Apollo.MutationFunction<
  PhoneNumberFormUpdateUserMutation,
  PhoneNumberFormUpdateUserMutationVariables
>;

/**
 * __usePhoneNumberFormUpdateUserMutation__
 *
 * To run a mutation, you first call `usePhoneNumberFormUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePhoneNumberFormUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [phoneNumberFormUpdateUserMutation, { data, loading, error }] = usePhoneNumberFormUpdateUserMutation({
 *   variables: {
 *      smsOptedOutGroups: // value for 'smsOptedOutGroups'
 *   },
 * });
 */
export function usePhoneNumberFormUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PhoneNumberFormUpdateUserMutation,
    PhoneNumberFormUpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PhoneNumberFormUpdateUserMutation,
    PhoneNumberFormUpdateUserMutationVariables
  >(PhoneNumberFormUpdateUserDocument, options);
}
export type PhoneNumberFormUpdateUserMutationHookResult = ReturnType<
  typeof usePhoneNumberFormUpdateUserMutation
>;
export type PhoneNumberFormUpdateUserMutationResult =
  Apollo.MutationResult<PhoneNumberFormUpdateUserMutation>;
export type PhoneNumberFormUpdateUserMutationOptions =
  Apollo.BaseMutationOptions<
    PhoneNumberFormUpdateUserMutation,
    PhoneNumberFormUpdateUserMutationVariables
  >;
