import {
  Button,
  ButtonColor,
  ButtonSize,
  ButtonWidth,
  Popover,
  ToastDuration,
  ToastType,
  useToast,
} from '@leland-dev/leland-ui-library';
import { type FC } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import {
  type PhoneNumber,
  SmsEnrollmentGroup,
} from '../../__generated-gql-types__/globalTypes';
import { DEFAULT_ERROR_MESSAGE } from '../../utils/constants';
import { wasMutationSuccessful } from '../../utils/mutations';
import { ErrorMessage } from '../inputs/ErrorMessage';

import {
  PhoneNumberInput,
  SUPPORTED_COUNTRY_CODES_TO_COUNTRY_INFO,
} from './PhoneNumberInput';
import {
  usePhoneNumberFormRequestPhoneVerificationCodeMutation,
  usePhoneNumberFormUpdateUserMutation,
} from './__generated-gql-types__/PhoneNumberForm.generated';

const REGISTER_OPTIONS = Object.freeze({
  COUNTRY_CODE: {
    required: {
      value: true,
      message: 'Please select your country',
    },
  },
  NATIONAL_FORMAT: {
    required: {
      value: true,
      message: 'Phone number is required',
    },
  },
});

interface PhoneNumberFormProps {
  defaultValues: Nullable<PhoneNumber>;
  onSuccess: (values: PhoneNumber) => void;
  closeModal: () => void;
}

export const PhoneNumberForm: FC<PhoneNumberFormProps> = ({
  defaultValues,
  onSuccess,
  closeModal,
}) => {
  const { showToast } = useToast();
  // const { logFeatureInteraction } = useFeatureInteractions();

  const [
    requestPhoneVerificationCode,
    { loading: requestPhoneVerificationCodeInFlight },
  ] = usePhoneNumberFormRequestPhoneVerificationCodeMutation();

  const [updateUser, { loading: updateUserInFlight }] =
    usePhoneNumberFormUpdateUserMutation();

  const {
    register,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm<PhoneNumber>({
    defaultValues: defaultValues ?? {},
  });

  register('countryCode', REGISTER_OPTIONS.COUNTRY_CODE);

  const errorMessage =
    errors.countryCode?.message ?? errors.nationalFormat?.message ?? null;

  const optOut = async () => {
    try {
      await updateUser({
        variables: {
          smsOptedOutGroups: [
            SmsEnrollmentGroup.CUSTOMER_COACHING_SESSION_REMINDER,
          ],
        },
      });

      // await logFeatureInteraction(FeatureInteraction.PHONE_NUMBER_MODAL);

      closeModal();
    } catch (e) {
      console.warn(e);
    }
  };

  const onSubmitWrapper: SubmitHandler<PhoneNumber> = async (values) => {
    try {
      const res = await requestPhoneVerificationCode({
        variables: {
          phoneNumber: `+${
            SUPPORTED_COUNTRY_CODES_TO_COUNTRY_INFO[values.countryCode]
              .callingCountryCode
          }${values.nationalFormat}`,
        },
      });

      if (wasMutationSuccessful(res)) {
        onSuccess(values);
      } else {
        // eslint-disable-next-line @typescript-eslint/only-throw-error
        throw res.errors;
      }
    } catch (e) {
      showToast({
        type: ToastType.ERROR,
        duration: ToastDuration.NORMAL,
        message: DEFAULT_ERROR_MESSAGE,
      });
      console.warn(e);
    }
  };

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmitWrapper)}>
      <h2 className="text-2xl font-medium leading-7 text-leland-gray-dark">
        Get reminded about your sessions
      </h2>
      <p className="mt-2 text-lg leading-6 text-leland-gray-light">
        Don&rsquo;t miss your coaching sessions! If you live in a{' '}
        <Popover content="Supported countries: Canada, Germany, India, United Kingdom, United States">
          <p className="underline">supported country</p>
        </Popover>
        , we&rsquo;ll send you SMS reminders before your sessions are about to
        start.
      </p>
      <PhoneNumberInput
        defaultValues={defaultValues}
        register={register('nationalFormat', REGISTER_OPTIONS.NATIONAL_FORMAT)}
        onCountrySelect={async (value: string) => {
          setValue('countryCode', value);
          await trigger('countryCode');
        }}
      />
      {errorMessage ? <ErrorMessage errorMessage={errorMessage} /> : null}
      <p className="mb-6 mt-2 text-sm leading-snug text-leland-gray-light">
        We&rsquo;ll send you a text to confirm your number. Standard message and
        data rates apply.{' '}
        <a
          className="underline"
          href="/legal/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </p>
      <Button
        type="submit"
        label="Send a code"
        buttonColor={ButtonColor.PRIMARY}
        width={ButtonWidth.FULL}
        size={ButtonSize.LARGE}
        disabled={requestPhoneVerificationCodeInFlight}
        rounded
      />
      <button
        type="button"
        className="mt-4 leading-5 text-leland-gray-light underline"
        onClick={optOut}
        disabled={updateUserInFlight}
      >
        I&rsquo;ll do this later
      </button>
    </form>
  );
};
