import {
  Button,
  ButtonColor,
  ButtonWidth,
  IconCheckmark,
  IconPhone,
  Modal,
  ModalContent,
  type ModalHook,
  ModalSize,
  useControlledModalState,
} from '@leland-dev/leland-ui-library';
import { type FC } from 'react';

import { CALENDLY_LINKS } from '../../utils/constants';

interface SalesModalHookProps {
  sectionTitle?: string;
  sectionInfo?: string;
}

interface SalesModalProps extends SalesModalHookProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const SalesModalContent: FC<SalesModalProps> = ({
  open,
  setOpen,
  sectionTitle,
  sectionInfo,
}) => {
  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalContent preventScroll size={ModalSize.SMALL}>
        <div className="space-y-5 p-5">
          <IconCheckmark className="mt-0.5 size-8 shrink-0 text-leland-primary" />
          {sectionTitle || sectionInfo ? (
            <div className="flex flex-col space-y-2 border-b pb-5">
              <h2 className="text-2xl font-medium leading-7 text-leland-gray-dark">
                {sectionTitle}
              </h2>
              <p className="text-leland-gray-light">{sectionInfo}</p>
            </div>
          ) : null}
          <div className="flex flex-col space-y-2 pb-5">
            <h2 className="text-lg font-medium"> Chat with our team</h2>
            <p className="text-leland-gray-light">
              Set up a call with a Leland team member to get exclusive deals and
              help you find the perfect coach.
            </p>
          </div>
          <a
            href={CALENDLY_LINKS.MEET_WITH_TEAM_MEMBER}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              label="Schedule a call"
              LeftIcon={IconPhone}
              width={ButtonWidth.FULL}
              buttonColor={ButtonColor.PRIMARY}
              onClick={() => {
                setOpen(false);
              }}
            />
          </a>
        </div>
      </ModalContent>
    </Modal>
  );
};

export const useSalesModal: ModalHook<SalesModalHookProps> = () => {
  const [open, setOpen] = useControlledModalState();

  const SalesModal: FC<SalesModalHookProps> = (props) => {
    return open ? (
      <SalesModalContent {...props} open={open} setOpen={setOpen} />
    ) : null;
  };

  return {
    open,
    setOpen,
    Modal: SalesModal,
  };
};
