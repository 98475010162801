import {
  Modal,
  ModalContent,
  type ModalHook,
  useControlledModalState,
} from '@leland-dev/leland-ui-library';
import { type FC, useCallback, useState } from 'react';

import { type PhoneNumber } from '../../__generated-gql-types__/globalTypes';
import { useAuth } from '../../context/AuthContext';

import { ConfirmPhoneNumberForm } from './ConfirmPhoneNumberForm';
import { PhoneNumberForm } from './PhoneNumberForm';
import { SUPPORTED_COUNTRY_CODES_TO_COUNTRY_INFO } from './PhoneNumberInput';

interface PhoneNumberModalHookProps {
  defaultValues?: Nullable<PhoneNumber>;
  openConfirmationModal?: boolean;
  onConfirm?: (phoneNumber?: Nullable<PhoneNumber>) => void;
}

interface PhoneNumberModalProps extends PhoneNumberModalHookProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const PhoneNumberModalContent: FC<PhoneNumberModalProps> = ({
  open,
  setOpen,
  defaultValues,
  openConfirmationModal,
  onConfirm,
}) => {
  const [showConfirmationForm, setShowConfirmationForm] = useState(
    openConfirmationModal,
  );
  const [phoneNumber, setPhoneNumber] = useState<Nullable<PhoneNumber>>(
    defaultValues ?? null,
  );

  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalContent header="Add your phone number" preventScroll>
        <div className="flex flex-col items-center p-9 text-center">
          {showConfirmationForm && phoneNumber ? (
            <ConfirmPhoneNumberForm
              phoneNumber={phoneNumber}
              onChangeNumber={() => setShowConfirmationForm(false)}
              onSuccess={(phoneNumber) => {
                onConfirm?.(phoneNumber);
                setOpen(false);
              }}
            />
          ) : (
            <PhoneNumberForm
              defaultValues={phoneNumber}
              onSuccess={(values) => {
                setPhoneNumber({
                  ...values,
                  callingCountryCode: `+${
                    SUPPORTED_COUNTRY_CODES_TO_COUNTRY_INFO[values.countryCode]
                      .callingCountryCode
                  }`,
                });
                setShowConfirmationForm(true);
              }}
              closeModal={() => setOpen(false)}
            />
          )}
        </div>
      </ModalContent>
    </Modal>
  );
};

export const usePhoneNumberModal = (): ReturnType<
  ModalHook<PhoneNumberModalHookProps>
> & { openModalIfNecessary: () => void } => {
  const { currentUser } = useAuth();
  const [open, setOpen] = useControlledModalState();

  const openModalIfNecessary = useCallback(() => {
    if (!currentUser?.phoneNumber) {
      setOpen(true);
    }
  }, [currentUser?.phoneNumber, setOpen]);

  const PhoneNumberModal: FC<PhoneNumberModalHookProps> = (props) => {
    return open ? (
      <PhoneNumberModalContent {...props} open={open} setOpen={setOpen} />
    ) : null;
  };

  return {
    open,
    setOpen,
    openModalIfNecessary,
    Modal: PhoneNumberModal,
  };
};
