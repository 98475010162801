// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
export type BaseEventCardCoachFragment = {
  __typename?: 'Coach';
  id: string;
  slug: string;
  reviewTotal: number;
  averageReviewRate: number;
  categoryInformationList?: Array<{
    __typename?: 'CategoryInformation';
    category: { __typename?: 'Category'; id: string; slug: string };
  }> | null;
  user: {
    __typename?: 'User';
    pictureLink?: string | null;
    firstName: string;
    lastName: string;
  };
};

export type BaseEventCardCategoryFragment = {
  __typename?: 'Category';
  id: string;
  slug: string;
};

export const BaseEventCardCoachFragmentDoc = gql`
  fragment BaseEventCard_Coach on Coach {
    id
    categoryInformationList {
      category {
        id
        slug
      }
    }
    user {
      pictureLink
      firstName
      lastName
    }
    slug
    reviewTotal
    averageReviewRate
  }
`;
export const BaseEventCardCategoryFragmentDoc = gql`
  fragment BaseEventCard_Category on Category {
    id
    slug
  }
`;
