import { type ReactElement } from 'react';

interface ErrorMessageProps {
  errorMessage: string;
}

export const ErrorMessage = ({
  errorMessage,
}: ErrorMessageProps): ReactElement => {
  return <p className="mt-1 text-sm text-leland-red">{errorMessage}</p>;
};
