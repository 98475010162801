import { IconInfo, Tooltip } from '@leland-dev/leland-ui-library';
import type {
  ChangeEventHandler,
  InputHTMLAttributes,
  KeyboardEventHandler,
  ReactElement,
} from 'react';
import type { FieldError, UseFormRegisterReturn } from 'react-hook-form';

interface ErrorMessageProps {
  className?: string;
  errorMessage: string;
}
export const ErrorMessage = ({
  className,
  errorMessage,
}: ErrorMessageProps): ReactElement => {
  return (
    <p className={`mt-2 text-sm text-leland-red ${className}`}>
      {errorMessage}
    </p>
  );
};
// by default numeric inputs allow scientific notation, thus ignore "e"
const IGNORED_NUMERIC_KEYS = new Set(['E', 'e', '+', '-', '.']);

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  subLabel?: string;
  flag?: string;
  className?: string;
  inputContainerClassName?: string;
  labelIsHidden?: boolean;
  register?: UseFormRegisterReturn;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  error?: FieldError;
  tooltip?: string;
  prefix?: string;
  subtlePrefix?: string;
  postfix?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const Input: React.FC<InputProps> = ({
  label,
  subLabel,
  flag,
  className,
  inputContainerClassName = '',
  labelIsHidden,
  register,
  onChange,
  onKeyDown,
  error,
  tooltip,
  prefix,
  subtlePrefix,
  postfix,
  onBlur,
  ...inputProps
}) => {
  const { type } = inputProps;
  const isNumericInput = type === 'number';

  return (
    <div className={className}>
      <div className="space-y-3">
        {!labelIsHidden ? (
          <label className="space-y-1 text-lg leading-6">
            <h3 className="text-leland-gray-dark">
              {label}
              {flag ? (
                <span className="ml-2 text-leland-gray-extra-light">
                  {flag}
                </span>
              ) : null}
              {tooltip ? (
                <Tooltip content={tooltip}>
                  <IconInfo className="ml-1 size-4 text-leland-gray-extra-light" />
                </Tooltip>
              ) : null}
            </h3>
            {subLabel ? (
              <p className="text-leland-gray-light">{subLabel}</p>
            ) : null}
          </label>
        ) : null}
        <div
          className={`flex items-center overflow-hidden rounded-lg border border-leland-gray-stroke transition duration-100 focus-within:border-leland-gray-extra-light ${inputContainerClassName}`}
        >
          {prefix ? (
            <div className="pointer-events-none whitespace-nowrap border border-transparent bg-gray-100 px-4 py-3 text-base text-leland-gray-light">
              <span>{prefix}</span>
            </div>
          ) : null}
          {subtlePrefix ? (
            <div className="pointer-events-none whitespace-nowrap border border-transparent py-3 pl-4 pr-0.5 text-base">
              <span>{subtlePrefix}</span>
            </div>
          ) : null}
          <input
            className={`block w-full bg-white p-4 shadow-none outline-none outline-0 ring-0 placeholder:text-gray-400 ${
              postfix ? 'rounded-r-none' : ''
            } ${prefix ? 'rounded-l-none' : ''} ${
              subtlePrefix ? 'rounded-l-none border-l-0 pl-0' : ''
            }`}
            {...inputProps}
            {...register}
            onChange={(e) => {
              void register?.onChange(e);
              onChange?.(e);
            }}
            onKeyDown={
              isNumericInput || onKeyDown
                ? (e) => {
                    if (isNumericInput && IGNORED_NUMERIC_KEYS.has(e.key)) {
                      e.preventDefault();
                    }
                    onKeyDown?.(e);
                  }
                : undefined
            }
            onWheel={isNumericInput ? (e) => e.currentTarget.blur() : undefined}
            onBlur={onBlur}
          />
          {postfix ? (
            <div className="pointer-events-none whitespace-nowrap border border-transparent bg-gray-100 p-4 text-base text-leland-gray-light">
              <span>{postfix}</span>
            </div>
          ) : null}
        </div>
      </div>

      {error?.message ? <ErrorMessage errorMessage={error.message} /> : null}
    </div>
  );
};

export default Input;
