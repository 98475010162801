import {
  Button,
  ButtonColor,
  ButtonSize,
  ButtonWidth,
  ToastDuration,
  ToastType,
  useToast,
} from '@leland-dev/leland-ui-library';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { type FC } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import { type PhoneNumber } from '../../__generated-gql-types__/globalTypes';
import { DEFAULT_ERROR_MESSAGE } from '../../utils/constants';
import { wasMutationSuccessful } from '../../utils/mutations';
import Input from '../inputs/Input';

import {
  type ConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutationVariables,
  useConfirmPhoneNumberFormRequestPhoneVerificationCodeMutation,
  useConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutation,
} from './__generated-gql-types__/ConfirmPhoneNumberForm.generated';

const REGISTER_OPTIONS = Object.freeze({
  CODE: {
    required: {
      value: true,
      message: 'Confirmation code is required',
    },
  },
});

interface ConfirmPhoneNumberFormProps {
  phoneNumber: PhoneNumber;
  onChangeNumber: () => void;
  onSuccess?: (phoneNumber?: Nullable<PhoneNumber>) => void;
}

export const ConfirmPhoneNumberForm: FC<ConfirmPhoneNumberFormProps> = ({
  phoneNumber,
  onChangeNumber,
  onSuccess,
}) => {
  const router = useRouter();
  const { showToast } = useToast();

  const [
    verifyAndSavePhoneNumber,
    { loading: verifyAndSavePhoneNumberInFlight },
  ] = useConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutation();

  const [
    requestPhoneVerificationCode,
    { loading: requestPhoneVerificationCodeInFlight },
  ] = useConfirmPhoneNumberFormRequestPhoneVerificationCodeMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutationVariables>();

  const phoneNumberFormatted = `${phoneNumber.callingCountryCode} ${phoneNumber.nationalFormat}`;

  const disabled =
    verifyAndSavePhoneNumberInFlight || requestPhoneVerificationCodeInFlight;

  const onProfileSettingsPage = router.pathname === '/settings/profile';

  const onSendAgain = async () => {
    try {
      const res = await requestPhoneVerificationCode({
        variables: {
          phoneNumber: phoneNumberFormatted,
        },
      });

      if (wasMutationSuccessful(res)) {
        showToast({
          type: ToastType.SUCCESS,
          duration: ToastDuration.NORMAL,
          message: 'Verification code sent.',
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/only-throw-error
        throw res.errors;
      }
    } catch (e) {
      showToast({
        type: ToastType.ERROR,
        duration: ToastDuration.NORMAL,
        message: 'Failed to send verification code. Please try again.',
      });
      console.warn(e);
    }
  };

  const onSubmitWrapper: SubmitHandler<
    ConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutationVariables
  > = async (values) => {
    try {
      const res = await verifyAndSavePhoneNumber({
        variables: {
          phoneNumber: phoneNumberFormatted,
          code: values.code,
        },
      });

      if (wasMutationSuccessful(res)) {
        onSuccess?.(res.data?.verifyAndSavePhoneNumber.phoneNumber);
        showToast({
          type: ToastType.SUCCESS,
          duration: ToastDuration.LONG,
          message: (
            <p className="text-lg text-leland-gray-dark">
              Phone number successfully verified.{' '}
              {onProfileSettingsPage ? null : (
                <Link
                  className="text-leland-gray-light underline"
                  href="/settings/profile"
                  target="_blank"
                  rel="noreferrer"
                >
                  Edit in your profile settings.
                </Link>
              )}
            </p>
          ),
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/only-throw-error
        throw res.errors;
      }
    } catch (e) {
      showToast({
        type: ToastType.ERROR,
        duration: ToastDuration.NORMAL,
        message: DEFAULT_ERROR_MESSAGE,
      });
      console.warn(e);
    }
  };

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmitWrapper)}>
      <h2 className="text-2xl font-medium leading-7 text-leland-gray-dark">
        Confirm your phone number
      </h2>
      <p className="mt-2 text-lg leading-6 text-leland-gray-light">
        Enter the 6-digit code Leland just sent to {phoneNumberFormatted}.{' '}
        <button
          type="button"
          className="underline"
          onClick={onChangeNumber}
          disabled={disabled}
        >
          Change number
        </button>
      </p>
      <Input
        className="mt-6"
        inputContainerClassName="py-1"
        label="Confirmation code"
        labelIsHidden
        placeholder="• • • • • •"
        type="number"
        register={register('code', REGISTER_OPTIONS.CODE)}
        error={errors.code}
      />
      <p className="mb-6 mt-2 text-start text-sm leading-snug text-leland-gray-light">
        Didn&rsquo;t get a text?{' '}
        <button
          type="button"
          className="link--primary underline"
          onClick={onSendAgain}
          disabled={disabled}
        >
          Send again
        </button>
      </p>
      <Button
        type="submit"
        label="Verify"
        buttonColor={ButtonColor.PRIMARY}
        width={ButtonWidth.FULL}
        size={ButtonSize.LARGE}
        disabled={disabled}
        rounded
      />
    </form>
  );
};
