// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutationVariables =
  Types.Exact<{
    phoneNumber: Types.Scalars['String']['input'];
    code: Types.Scalars['String']['input'];
  }>;

export type ConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutation = {
  __typename?: 'Mutation';
  verifyAndSavePhoneNumber: {
    __typename?: 'User';
    id: string;
    phoneNumber?: {
      __typename?: 'PhoneNumber';
      nationalFormat: string;
      countryCode: string;
      callingCountryCode: string;
    } | null;
  };
};

export type ConfirmPhoneNumberFormRequestPhoneVerificationCodeMutationVariables =
  Types.Exact<{
    phoneNumber: Types.Scalars['String']['input'];
  }>;

export type ConfirmPhoneNumberFormRequestPhoneVerificationCodeMutation = {
  __typename?: 'Mutation';
  requestPhoneVerificationCode: boolean;
};

export const ConfirmPhoneNumberFormVerifyAndSavePhoneNumberDocument = gql`
  mutation ConfirmPhoneNumberForm_VerifyAndSavePhoneNumber(
    $phoneNumber: String!
    $code: String!
  ) {
    verifyAndSavePhoneNumber(phoneNumber: $phoneNumber, code: $code) {
      id
      phoneNumber {
        nationalFormat
        countryCode
        callingCountryCode
      }
    }
  }
`;
export type ConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutationFn =
  Apollo.MutationFunction<
    ConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutation,
    ConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutationVariables
  >;

/**
 * __useConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutation__
 *
 * To run a mutation, you first call `useConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPhoneNumberFormVerifyAndSavePhoneNumberMutation, { data, loading, error }] = useConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutation,
    ConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutation,
    ConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutationVariables
  >(ConfirmPhoneNumberFormVerifyAndSavePhoneNumberDocument, options);
}
export type ConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutationHookResult =
  ReturnType<typeof useConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutation>;
export type ConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutationResult =
  Apollo.MutationResult<ConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutation>;
export type ConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutationOptions =
  Apollo.BaseMutationOptions<
    ConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutation,
    ConfirmPhoneNumberFormVerifyAndSavePhoneNumberMutationVariables
  >;
export const ConfirmPhoneNumberFormRequestPhoneVerificationCodeDocument = gql`
  mutation ConfirmPhoneNumberForm_RequestPhoneVerificationCode(
    $phoneNumber: String!
  ) {
    requestPhoneVerificationCode(phoneNumber: $phoneNumber)
  }
`;
export type ConfirmPhoneNumberFormRequestPhoneVerificationCodeMutationFn =
  Apollo.MutationFunction<
    ConfirmPhoneNumberFormRequestPhoneVerificationCodeMutation,
    ConfirmPhoneNumberFormRequestPhoneVerificationCodeMutationVariables
  >;

/**
 * __useConfirmPhoneNumberFormRequestPhoneVerificationCodeMutation__
 *
 * To run a mutation, you first call `useConfirmPhoneNumberFormRequestPhoneVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPhoneNumberFormRequestPhoneVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPhoneNumberFormRequestPhoneVerificationCodeMutation, { data, loading, error }] = useConfirmPhoneNumberFormRequestPhoneVerificationCodeMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useConfirmPhoneNumberFormRequestPhoneVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmPhoneNumberFormRequestPhoneVerificationCodeMutation,
    ConfirmPhoneNumberFormRequestPhoneVerificationCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmPhoneNumberFormRequestPhoneVerificationCodeMutation,
    ConfirmPhoneNumberFormRequestPhoneVerificationCodeMutationVariables
  >(ConfirmPhoneNumberFormRequestPhoneVerificationCodeDocument, options);
}
export type ConfirmPhoneNumberFormRequestPhoneVerificationCodeMutationHookResult =
  ReturnType<
    typeof useConfirmPhoneNumberFormRequestPhoneVerificationCodeMutation
  >;
export type ConfirmPhoneNumberFormRequestPhoneVerificationCodeMutationResult =
  Apollo.MutationResult<ConfirmPhoneNumberFormRequestPhoneVerificationCodeMutation>;
export type ConfirmPhoneNumberFormRequestPhoneVerificationCodeMutationOptions =
  Apollo.BaseMutationOptions<
    ConfirmPhoneNumberFormRequestPhoneVerificationCodeMutation,
    ConfirmPhoneNumberFormRequestPhoneVerificationCodeMutationVariables
  >;
